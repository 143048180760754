<template>
    <div>
        <v-app-bar
            flat
            color="grey lighten-4"
            dense
            class="elevation-0"
        >   
            <v-icon left>mdi-receipt</v-icon>
            <v-toolbar-title class="body-2">Račun</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="toggleCardContent('card1')">
                <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </v-btn>
        </v-app-bar>

        <v-divider v-show="card1"></v-divider>
        <v-card-text v-show="card1">      
            <v-container grid-list-xl text-x-center align-center>
                <div v-if="invoice != null && paidApplication != null">
                    <div v-if="$vuetify.breakpoint.mdAndDown">
                        <v-layout row wrap align-center justify-center fill height>
                            <v-flex xs12>
                                <span class="subtitle-1">
                                    <v-icon left>mdi-check</v-icon> 
                                    Vloga je bila plačana.
                                </span>   
                            </v-flex>
                            <v-flex xs12>
                                <v-btn dark color="#546E7A" @click="viewInvoice()" block outlined>
                                    <v-icon small left>mdi-receipt</v-icon>
                                    Račun
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-else>
                        <v-list>
                            <v-list-item
                            @click="viewInvoice()"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-check</v-icon>
                                </v-list-item-icon>
                        
                                <v-list-item-content >
                                    <v-list-item-title class="body-2">Vloga je bila plačana.</v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-btn dark color="#546E7A" small @click="viewInvoice()">
                                        <v-icon small left>mdi-receipt</v-icon>
                                        Račun
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </div>
                </div>
                <div v-else>
                    <div v-if="$vuetify.breakpoint.mdAndDown">
                        <v-layout row wrap align-center justify-center fill height>
                            <v-flex xs12>
                                <span class="subtitle-1">
                                    <v-icon class="p--icon" medium color="error" left>mdi-information</v-icon> 
                                    Vloga še ni bila plačana. 
                                </span>   
                            </v-flex>
                            <v-flex xs12>
                                <v-btn dark color="success" @click="showDialog()" block outlined>
                                    <v-icon small left>mdi-forward</v-icon>
                                    Ustvari račun
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-else>
                        <v-list>
                            <v-list-item
                            @click="showDialog()"
                            >
                                <v-list-item-icon>
                                    <v-icon color="error">mdi-information</v-icon>
                                </v-list-item-icon>
                        
                                <v-list-item-content >
                                    <v-list-item-title class="body-2">Vloga še ni bila plačana.</v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-btn dark color="success" small @click="showDialog()">
                                        <v-icon small left>mdi-forward</v-icon>
                                        Ustvari račun
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </div>
                </div>
            </v-container>
        </v-card-text>

        <v-dialog v-model="dialog" scrollable max-width="650px">
            <v-card>
                <v-card-title id="dialog_card_title">
                    Ste prepričani, da želite ustvariti račun za vlogo št. #{{ application.id }}?
                </v-card-title>
                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn :disabled="disableSubmit" :loading="loading" color="success" text @click="generateInvoice()"><v-icon left>mdi-arrow-right</v-icon>Generiraj račun</v-btn>
                    <v-btn :disabled="disableSubmit" color="error" text  @click="cancel()"><v-icon left>mdi-close</v-icon>{{ closeWindow }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { API_ENDPOINT,} from '@/helpers/api'

export default {
    name: 'invoice',
    props: [
        'application',
        'showContent'
    ],

    data: () => ({
        card1: true,

        applicationId: null,
        applicationUuid: null,
        paidApplication: null,
        invoice: null,
        dialog: false,

        disableSubmit: false,
        loading: false,

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        closeWindow: "Prekliči"
    }),

    watch: {

    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        }
    },

    methods: {
        toggleCardContent(card) {
            if(card == 'card1') {
                this.card1 = !this.card1;
            }
        },

        generateInvoice() {
            this.loading = true
            this.disableSubmit = true

            this.$store.dispatch('PARKING_APPLICATION_GENERATE_INVOICE', this.applicationId)
            .then(response => {
                window.console.log("Ustvari račun");
                window.console.log(response)

                this.paidApplication = response.data.paid_application
                this.invoice = response.data.invoice

                this.backendMessages.push("Račun za vlogo št. #" + this.applicationId + " je bil uspešno ustvarjen.");
                this.closeWindow = 'Zapri'

            })
            .catch(error => {
                window.console.error("Invoice@generateInvoice");
                window.console.log(error)
                window.console.log(error.response)

                this.backendErrorMessages.push('Pri ustvarjanju računa je prišlo do napake.');
            })
            .finally(() => {
                this.loading = false
                this.disableSubmit = false
                this.cancel()
            })
        },

        viewInvoice() {
            let downloadUrl = API_ENDPOINT + 'v1/files/applications/parking/' + this.applicationId + '/' + this.application.uuid +'/invoice/view'
            Object.assign(document.createElement('a'), {target: '_blank',href: downloadUrl}).click();
        },

        getApplicationData() {
            var vm = this;
            this.$store.dispatch("EDIT_APPLICATION", vm.application.uuid)
            .then(response => {
                //window.console.log(response.data.data)
                vm.application = response.data.data
                vm.applicationId = response.data.data.id
                vm.applicationType = (response.data.data.permit_type != null) ? response.data.data.permit_type.type: null
                vm.digitalApplication = response.data.data.digital;

            })
            .catch(error => {
                window.console.log(error)
            })
            .finally(() => {
                setTimeout(() => { 
                    vm.loading = false
                    this.$store.commit('SET_LOADER', false) 
                }, 400)
            })
        },

        showDialog() {
            this.dialog = true
        },

        cancel() {
            this.dialog = false
        },
    },

    created() {

    },

    mounted() {
        //window.console.log("Invoice.vue")
        //window.console.log(this.application)
        this.applicationId = this.application.id
        this.applicationUuid = this.application.uuid
        this.paidApplication = this.application.paid_application
        this.invoice = this.application.invoice

        var vm = this
        if(this.showContent == true) {
            this.card1 = true;
        }
        else {
            this.card1 = false;
        }
    },

    beforeDestroy() {

    },

    destroyed() {

    }
}

</script>

<style scoped>

    #dialog_card_title {
        font-size: 16px !important;
    }

</style>